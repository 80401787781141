import { AppProps } from 'next/app'
import React, { useEffect } from 'react'

import CookieConsent from '@aletheia/common/components/CookieConsent'
import Intercom from '@aletheia/common/components/Intercom/snippet'
import Layout from '@aletheia/common/components/Layout'
import HeadTags from '@aletheia/common/components/Layout/HeadTags'
import Logo from '@aletheia/common/components/Logo'
import AnalyticsProvider from '@aletheia/common/components/Segment'

import CookieBanner from '../components/CookieConsent/CookieBanner'
import Footer from '../components/Footer'
import Providers from '../components/Providers'
import { routes, navUserLoggedOutRoutes } from '../globals/routes'
import theme from '../globals/theme'

const MyApp: React.FC<AppProps> = ({
  Component,
  pageProps,
}) => {
  // Remove the server-side injected CSS.
  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles?.parentElement) {
      jssStyles.parentElement.removeChild(jssStyles)
    }
  }, [])

  const providersProps = {
    theme,
  }

  return (
    <>
      <HeadTags>
        <title>Effective Altruism Funds</title>
      </HeadTags>
      <AnalyticsProvider
        writeKey={
          process.env.NEXT_PUBLIC_FEATURE_FLAG_COOKIE_CONSENT === 'true'
            ? undefined
            : process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY
        }
      >
        <Intercom appId={process.env.NEXT_PUBLIC_INTERCOM_APP_ID} />
        <Providers {...providersProps}>
          <Layout
            Logo={Logo}
            Component={() => <Component {...pageProps} />}
            Footer={Footer}
            navRoutes={routes}
            navUserLoggedInRoutes={navUserLoggedOutRoutes}
            navUserLoggedOutRoutes={navUserLoggedOutRoutes}
          />
          <CookieConsent
            segmentWriteKey={
              process.env.NEXT_PUBLIC_FEATURE_FLAG_COOKIE_CONSENT === 'true'
                ? process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY
                : undefined
            }
            BannerContent={CookieBanner}
          />
        </Providers>
      </AnalyticsProvider>
    </>
  )
}

export default MyApp
