import { ThemeProvider, Theme, CssBaseline } from '@material-ui/core'
import React from 'react'
import { CookiesProvider } from 'react-cookie'
import { IntlProvider } from 'react-intl'

import { ReferralsProvider } from '@aletheia/common/components/Referrals'

export type SharedProvidersProps = {
  theme: Theme
  requiredRoles?: string[]
}

const SharedProviders: React.FC<SharedProvidersProps> = ({
  children,
  theme,
}) => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <CookiesProvider>
          <ReferralsProvider>
            <IntlProvider locale="en-US">{children}</IntlProvider>
          </ReferralsProvider>
        </CookiesProvider>
      </ThemeProvider>
    </>
  )
}

export default SharedProviders
